.qc-cmp-cleanslate,
.qc-cmp-cleanslate h1,
.qc-cmp-cleanslate h2,
.qc-cmp-cleanslate h3,
.qc-cmp-cleanslate h4,
.qc-cmp-cleanslate h5,
.qc-cmp-cleanslate h6,
.qc-cmp-cleanslate p,
.qc-cmp-cleanslate td,
.qc-cmp-cleanslate dl,
.qc-cmp-cleanslate tr,
.qc-cmp-cleanslate dt,
.qc-cmp-cleanslate ol,
.qc-cmp-cleanslate form,
.qc-cmp-cleanslate select,
.qc-cmp-cleanslate option,
.qc-cmp-cleanslate pre,
.qc-cmp-cleanslate div,
.qc-cmp-cleanslate table,
.qc-cmp-cleanslate th,
.qc-cmp-cleanslate tbody,
.qc-cmp-cleanslate tfoot,
.qc-cmp-cleanslate caption,
.qc-cmp-cleanslate thead,
.qc-cmp-cleanslate ul,
.qc-cmp-cleanslate li,
.qc-cmp-cleanslate address,
.qc-cmp-cleanslate blockquote,
.qc-cmp-cleanslate dd,
.qc-cmp-cleanslate fieldset,
.qc-cmp-cleanslate li,
.qc-cmp-cleanslate iframe,
.qc-cmp-cleanslate strong,
.qc-cmp-cleanslate legend,
.qc-cmp-cleanslate em,
.qc-cmp-cleanslate summary,
.qc-cmp-cleanslate cite,
.qc-cmp-cleanslate span,
.qc-cmp-cleanslate input,
.qc-cmp-cleanslate sup,
.qc-cmp-cleanslate label,
.qc-cmp-cleanslate dfn,
.qc-cmp-cleanslate object,
.qc-cmp-cleanslate big,
.qc-cmp-cleanslate q,
.qc-cmp-cleanslate samp,
.qc-cmp-cleanslate acronym,
.qc-cmp-cleanslate small,
.qc-cmp-cleanslate img,
.qc-cmp-cleanslate strike,
.qc-cmp-cleanslate code,
.qc-cmp-cleanslate sub,
.qc-cmp-cleanslate ins,
.qc-cmp-cleanslate textarea,
.qc-cmp-cleanslate button,
.qc-cmp-cleanslate var,
.qc-cmp-cleanslate a,
.qc-cmp-cleanslate abbr,
.qc-cmp-cleanslate applet,
.qc-cmp-cleanslate del,
.qc-cmp-cleanslate kbd,
.qc-cmp-cleanslate tt,
.qc-cmp-cleanslate b,
.qc-cmp-cleanslate i,
.qc-cmp-cleanslate hr,
.qc-cmp-cleanslate article,
.qc-cmp-cleanslate aside,
.qc-cmp-cleanslate figure,
.qc-cmp-cleanslate figcaption,
.qc-cmp-cleanslate footer,
.qc-cmp-cleanslate header,
.qc-cmp-cleanslate menu,
.qc-cmp-cleanslate nav,
.qc-cmp-cleanslate section,
.qc-cmp-cleanslate time,
.qc-cmp-cleanslate mark,
.qc-cmp-cleanslate audio,
.qc-cmp-cleanslate video,
.qc-cmp-cleanslate abbr,
.qc-cmp-cleanslate address,
.qc-cmp-cleanslate area,
.qc-cmp-cleanslate blockquote,
.qc-cmp-cleanslate canvas,
.qc-cmp-cleanslate caption,
.qc-cmp-cleanslate cite,
.qc-cmp-cleanslate code,
.qc-cmp-cleanslate colgroup,
.qc-cmp-cleanslate col,
.qc-cmp-cleanslate datalist,
.qc-cmp-cleanslate fieldset,
.qc-cmp-cleanslate main,
.qc-cmp-cleanslate map,
.qc-cmp-cleanslate meta,
.qc-cmp-cleanslate optgroup,
.qc-cmp-cleanslate output,
.qc-cmp-cleanslate progress,
.qc-cmp-cleanslate svg {
  background-attachment: scroll;
  background-color: transparent;
  background-image: none;
  bottom: auto;
  clear: none;
  clip: auto;
  color: inherit;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: inherit;
  display: inline;
  float: none;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  height: auto;
  left: auto;
  letter-spacing: normal;
  line-height: inherit;
  list-style-type: inherit;
  list-style-position: outside;
  list-style-image: none;
  max-height: none;
  max-width: none;
  min-height: 0px;
  min-width: 0px;
  opacity: 1;
  position: static;
  quotes: '' '';
  right: auto;
  table-layout: auto;
  text-align: inherit;
  text-indent: 0px;
  text-transform: none;
  top: auto;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: inherit;
  white-space-collapse: collapse;
  text-wrap: wrap;
  width: 100%;
  word-spacing: normal;
  z-index: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-size: auto;
  -webkit-border-image: none;
  box-shadow: none;
  box-sizing: content-box;
  column-count: auto;
  column-gap: normal;
  column-width: auto;
  font-feature-settings: normal;
  overflow-x: visible;
  overflow-y: visible;
  hyphens: manual;
  perspective: none;
  perspective-origin: 50% 50%;
  backface-visibility: visible;
  text-shadow: none;
  transform: none;
  transform-origin: 50% 50%;
  transform-style: flat;
  word-break: normal;
  background-position: 0px 0px;
  background-repeat: repeat;
  border-color: currentcolor;
  border-style: none;
  border-width: medium;
  font-variant: normal;
  margin: 0px;
  padding: 0px;
  text-decoration: inherit;
  border-image: none 100% / 1 / 0 stretch;
  border-radius: 0px;
  column-rule: black;
  transition: all 0s ease 0s;
}

.qc-cmp-cleanslate,
.qc-cmp-cleanslate h3,
.qc-cmp-cleanslate h5,
.qc-cmp-cleanslate p,
.qc-cmp-cleanslate h1,
.qc-cmp-cleanslate dl,
.qc-cmp-cleanslate dt,
.qc-cmp-cleanslate h6,
.qc-cmp-cleanslate ol,
.qc-cmp-cleanslate form,
.qc-cmp-cleanslate option,
.qc-cmp-cleanslate pre,
.qc-cmp-cleanslate div,
.qc-cmp-cleanslate h2,
.qc-cmp-cleanslate caption,
.qc-cmp-cleanslate h4,
.qc-cmp-cleanslate ul,
.qc-cmp-cleanslate address,
.qc-cmp-cleanslate blockquote,
.qc-cmp-cleanslate dd,
.qc-cmp-cleanslate fieldset,
.qc-cmp-cleanslate hr,
.qc-cmp-cleanslate article,
.qc-cmp-cleanslate dialog,
.qc-cmp-cleanslate figure,
.qc-cmp-cleanslate footer,
.qc-cmp-cleanslate header,
.qc-cmp-cleanslate hgroup,
.qc-cmp-cleanslate menu,
.qc-cmp-cleanslate nav,
.qc-cmp-cleanslate section,
.qc-cmp-cleanslate audio,
.qc-cmp-cleanslate video,
.qc-cmp-cleanslate address,
.qc-cmp-cleanslate blockquote,
.qc-cmp-cleanslate colgroup,
.qc-cmp-cleanslate main,
.qc-cmp-cleanslate progress,
.qc-cmp-cleanslate summary {
  display: block;
}

.qc-cmp-cleanslate {
  font-size: medium;
  line-height: 1;
  direction: ltr;
  text-align: start;
  font-family: 'Times New Roman', Times, serif;
  color: black;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  list-style-type: disc;
  height: 100vh !important;
}

.css-1oqkz8s.css-1oqkz8s {
  background-color: rgba(33, 41, 52, 0.75);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 938px;
  width: 100vw;
  overflow: hidden;
  color: rgb(20, 30, 35);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2147483647;
}

.css-1oqkz8s.css-1oqkz8s * {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  line-height: 1;
}

.css-1unuaz2.css-1unuaz2 {
  background: rgb(255, 255, 255);
  height: 95%;
  width: 100%;
  display: grid;
  grid-template-rows: none;
  justify-items: center;
  position: relative;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 {
    height: auto;
    max-height: none;
    width: 770px;
    grid-template-rows: auto 1fr;
  }
}
@media only screen and (max-width: 767px) and (min-width: 361px) and (min-height: 300px) {
  .css-1unuaz2.css-1unuaz2.css-1unuaz2.css-1unuaz2 {
    display: grid;
    grid-template-rows: none;
    height: 70%;
  }
}

@media (max-width: 767px) {
  .css-1unuaz2.css-1unuaz2.css-1unuaz2.css-1unuaz2 {
    max-height: 100%;
    margin-top: 0px;
  }
}

.css-1unuaz2.css-1unuaz2 div {
  color: rgb(20, 30, 35);
  font-size: 14px;
  line-height: 21px;
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-summary-section {
  display: grid;
  justify-items: center;
  overflow: hidden;
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info {
  padding: 5% 20px 2%;
  text-align: center;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info {
    padding: 15px 30px;
    width: 100%;
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info div {
  text-align: left;
  margin-bottom: 3%;
  max-height: 20vh;
  font-size: 13px;
  overflow: auto;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info div {
    margin-bottom: 0px;
    max-height: none;
    font-size: 14px;
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info .qc-cmp2-publisher-logo-container {
  overflow: unset;
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info .qc-cmp2-publisher-logo-container {
  position: relative;
  text-align: center;
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-summary-section .qc-cmp2-publisher-logo-container {
  height: 100%;
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-summary-section .qc-cmp2-consent-info div {
  max-height: unset;
}

.qc-cmp-cleanslate figure {
  margin: 0px;
}

.css-1oqkz8s.css-1oqkz8s * {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  line-height: 1;
}

.css-1unuaz2.css-1unuaz2 h2 {
  color: rgb(20, 30, 35);
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 h2 {
    font-size: 20px;
    margin-top: 10px;
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info h2 {
  margin-bottom: 3%;
  padding-left: 60px;
  padding-right: 45px;
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-summary-section h2 {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 .qc-cmp2-consent-info .qc-cmp2-publisher-logo-container h2 {
    margin-bottom: 10px;
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-summary-section .qc-cmp2-summary-info {
  padding-bottom: 30px;
  height: 100%;
  overflow-y: auto;
  font-size: 14px;
}

.css-1sjf9qp.css-1sjf9qp {
  place-content: center;
  backface-visibility: hidden;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  line-height: 0;
  min-height: 34px;
  -webkit-box-pack: center;
  margin: 0px 0.5em 0.5em;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.35s ease 0s;
  white-space: nowrap;
  border: none;
  font-size: 14px;
  padding: 5px 0px;
  width: auto;
  height: auto;
  font-weight: 500;
  letter-spacing: 0px;
  background: none;
  color: var(--color-primary-500);
  box-shadow: none;
}

@media (min-width: 768px) {
  .css-1sjf9qp.css-1sjf9qp {
    font-size: 14px;
  }

  .css-1sjf9qp.css-1sjf9qp {
    min-width: 190px;
    min-height: 40px;
    font-size: 14px;
    padding: 0px;
  }
}

.css-1sjf9qp.css-1sjf9qp.qc-cmp2-link-inline {
  display: inline-flex;
  min-width: 0px;
  min-height: 0px;
  text-transform: none;
  margin: 0px;
  padding: 0px;
  line-height: 1;
  color: var(--color-primary-400) !important;
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-footer {
  background-color: transparent;
  border-top: 1px solid rgb(216, 216, 216);
  padding: 3% 15px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.css-s64zte.css-s64zte .qc-cmp2-footer .qc-cmp2-footer-links button:not(.qc-cmp2-link-active),
.css-s64zte.css-s64zte .qc-cmp2-consent-info .qc-cmp2-header-links button,
.css-8rroe4.css-8rroe4,
.css-p8ohu8.css-p8ohu8 .qc-cmp2-list-item .qc-cmp2-expandable-list p a,
.css-p8ohu8.css-p8ohu8 .qc-cmp2-list-item .qc-cmp2-expandable-list li a {
  color: var(--color-primary-500) !important;
}

.css-ach1mn.css-ach1mn.css-ach1mn.css-ach1mn {
  background: var(--color-primary-400) !important;
  border-color: var(--color-primary-400) !important;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 .qc-cmp2-footer {
    padding: 13px 30px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 4px 0px;
  }

  .css-s64zte.css-s64zte .qc-cmp2-footer .qc-cmp2-footer-links button:not(.qc-cmp2-link-active) {
    background: var(--color-primary-200) !important;
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-footer * {
  box-sizing: border-box;
}

@media (max-width: 767px) {
  @media (orientation: portrait) {
    .css-1unuaz2.css-1unuaz2.css-1unuaz2.css-1unuaz2 .qc-cmp2-footer {
      margin-top: 5%;
    }
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-footer .qc-cmp2-summary-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 .qc-cmp2-footer .qc-cmp2-summary-buttons {
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 5px 0px;
  }
}

.qc-cmp-cleanslate button,
.qc-cmp-cleanslate input[type='submit'] {
  text-align: center;
  padding: 2px 6px 3px;
  border-radius: 0px;
  text-decoration: none;
  font-family: arial, helvetica, sans-serif;
  font-size: small;
  color: buttontext;
  border: 1px solid rgb(166, 166, 166);
  background: none;
  filter: none;
  box-shadow: none;
  outline: initial;
}

.qc-cmp-cleanslate button {
  padding: 1px 6px 2px;
  margin-right: 5px;
}

.css-1hy2vtq.css-1hy2vtq {
  place-content: center;
  backface-visibility: hidden;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 0;
  min-height: 34px;
  -webkit-box-pack: center;
  letter-spacing: 2px;
  margin: 0.5em;
  padding: 12px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.35s ease 0s;
  white-space: nowrap;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px 1px;
  background: rgb(255, 255, 255);
  border: 1px solid var(--color-primary-500);
  color: var(--color-primary-500);
}

@media (min-width: 768px) {
  .css-1hy2vtq.css-1hy2vtq {
    min-width: 190px;
    min-height: 40px;
    font-size: 14px;
    padding: 0px;
  }
}

.css-47sehv.css-47sehv {
  place-content: center;
  backface-visibility: hidden;
  border: none;
  background: var(--color-primary-500);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 0;
  min-height: 34px;
  -webkit-box-pack: center;
  letter-spacing: 2px;
  margin: 0.5em;
  padding: 12px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.35s ease 0s;
  white-space: nowrap;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px 1px;
}

@media (min-width: 768px) {
  .css-47sehv.css-47sehv {
    min-width: 190px;
    min-height: 40px;
    font-size: 14px;
    padding: 0px;
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-footer .qc-cmp2-summary-buttons button {
  width: 100%;
  margin-bottom: 10px;
  order: 2;
}

@media (min-width: 768px) {
  .css-1unuaz2.css-1unuaz2 .qc-cmp2-footer .qc-cmp2-summary-buttons button {
    margin-bottom: 0px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .css-1unuaz2.css-1unuaz2.css-1unuaz2.css-1unuaz2
    .qc-cmp2-footer
    .qc-cmp2-summary-buttons
    button:last-of-type {
    order: 0;
  }
}

.css-1unuaz2.css-1unuaz2 .qc-cmp2-footer .qc-cmp2-summary-buttons button span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ----- */
.qc-cmp-button.qc-cmp-secondary-button:hover {
  background-color: #368bd6 !important;
  border-color: transparent !important;
}
.qc-cmp-button.qc-cmp-secondary-button:hover {
  color: #ffffff !important;
}
.qc-cmp-button.qc-cmp-secondary-button {
  color: #368bd6 !important;
}
.qc-cmp-button.qc-cmp-secondary-button {
  background-color: #eee !important;
  border-color: transparent !important;
}
.qc-cmp2-container * {
  font-family: var(--font-family-system), 'Objektiv', sans-serif !important;
  letter-spacing: 0 !important;
  line-height: 1.5 !important;
  overscroll-behavior: contain;
}
.qc-cmp2-container * button {
  font-family: var(--font-family-system), 'Objektiv', sans-serif !important;
  line-height: 1.5 !important;
  box-shadow: none !important;
  border-radius: var(--border-radius) !important;
  min-height: var(--button-large-height) !important;
  font-size: 16px;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.2px !important;
  transition: all 0.2s !important;
}

.qc-cmp2-container * button[mode='secondary'] {
  color: var(--button-primary-base-bg-color) !important;
  border-color: var(--button-primary-base-bg-color) !important;
  background: white !important;
  border-radius: var(--border-radius) !important;
}
.qc-cmp2-container * button[mode='primary'] {
  background: var(--button-primary-base-bg-color) !important;
  border-radius: var(--border-radius) !important;
}

.css-1sxa6vy.css-1sxa6vy
  .qc-cmp2-consent-info
  .qc-cmp2-publisher-logo-container
  .qc-cmp2-home-button {
  color: var(--color-primary-500) !important;
}

.css-306jtp.css-306jtp.css-306jtp.css-306jtp {
  background: var(--color-primary-200) !important;
  border-color: var(--color-primary-200) !important;
}
.css-306jtp.css-306jtp.css-306jtp.css-306jtp p.text.on {
  color: var(--color-primary-500) !important;
}
.css-1sxa6vy.css-1sxa6vy .qc-cmp2-consent-info .qc-cmp2-publisher-logo-container .description,
.qc-cmp2-scrollable-section {
  scrollbar-color: var(--color-primary-500) var(--color-primary-500);
  overscroll-behavior: contain;
}

.css-1sxa6vy.css-1sxa6vy
  .qc-cmp2-consent-info
  .qc-cmp2-publisher-logo-container
  .qc-cmp2-home-button
  svg
  use {
  fill: var(--color-primary-500) !important;
}

.css-2n8zm6 .loader .line-scale > div {
  background: var(--color-primary-500) !important;
}
